import React, { useEffect } from "react";
import ReactGA from 'react-ga4';
ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
function LinkedinIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />
      <rect width="4" height="12" x="2" y="9" />
      <circle cx="4" cy="4" r="2" />
    </svg>
  );
}

function Header() {
  return (
    <header className="px-4 lg:px-6 h-14 flex items-center">
      <span
        href="#"
        className="flex items-center justify-center"
        prefetch={false}
      >
        <img src="/1024-logo.png" alt="logo" className=" w-12 aspect-square" />
        <span className="sr-only">AutoResume</span>
      </span>
      <nav className="ml-auto flex gap-4 sm:gap-6">
        <a
          href="#features"
          className="text-sm font-medium hover:underline underline-offset-4"
          prefetch={false}
        >
          Features
        </a>
        <a
          href="#pricing"
          className="text-sm font-medium hover:underline underline-offset-4"
          prefetch={false}
        >
          Pricing
        </a>
        <a
          href="#about"
          className="text-sm font-medium hover:underline underline-offset-4"
          prefetch={false}
        >
          About
        </a>
        {/* <a href="#" className="text-sm font-medium hover:underline underline-offset-4" prefetch={false}>
          Contact
        </a> */}
      </nav>
    </header>
  );
}

function HeroSection() {
  return (
    <section className="w-full py-12 md:py-24 lg:py-32 ">
      <div className="container grid gap-6 px-4 md:px-6 lg:grid-cols-2 lg:gap-12 m-auto">
        <div className="space-y-4">
          <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
            Automaticaly create resume tailored to job
          </h1>
          <p className="max-w-[600px] text-muted-foreground md:text-xl">
            We all know , if we tailor the resume to Job Description, it has
            better chances, but its not convenient. We Do it automatically for
            all jobs
          </p>
          <div className="flex flex-col gap-2 min-[400px]:flex-row">
            <a
              href="https://chromewebstore.google.com/detail/autoresume/hmkameegcicjjiahjccjabfmlkggfocf"
              className="inline-flex h-10 items-center justify-center rounded-md bg-primary px-8 text-sm font-medium text-primary-foreground shadow transition-colors hover:bg-primary/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
              prefetch={false}
              onClick={()=>{
                ReactGA.event({
                  category: 'User',
                  action: 'Clicked Try For Free'
                });
              }}
            >
              Try for Free
            </a>
            <a
              href="#"
              className="inline-flex h-10 items-center justify-center rounded-md border border-input bg-background px-8 text-sm font-medium shadow-sm transition-colors hover:bg-accent hover:text-accent-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
              prefetch={false}
            >
              Learn More
            </a>
          </div>
        </div>
        <img
          src="/img2.jpg"
          width="550"
          height="550"
          alt="Hero"
          className="mx-auto aspect-video overflow-hidden rounded-xl object-cover sm:w-full"
        />
      </div>
    </section>
  );
}
function FeatureSection() {
  return (
    <section id="features" className="w-full py-12 md:py-24 lg:py-32 bg-muted">
      <div className="container px-4 md:px-6 m-auto">
        <div className="flex flex-col items-center justify-center space-y-4 text-center">
          <div className="space-y-2">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl">
              Elevate Your Career with AutoResume
            </h2>
            <p className="max-w-[900px] text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
              Experience the power of AI-driven resume building. AutoResume helps you create impeccable resumes tailored for any job, streamlining your job search and boosting your career prospects.
            </p>
          </div>
        </div>
        <div className="mx-auto grid max-w-5xl items-center gap-6 py-12 lg:grid-cols-2 lg:gap-12">
          <img
            src="/image7.jpg"
            width="550"
            height="310"
            alt="Resume Building"
            className="mx-auto aspect-video overflow-hidden rounded-xl object-cover object-center sm:w-full lg:order-last"
          />
          <div className="flex flex-col justify-center space-y-4">
            <ul className="grid gap-6">
              <li>
                <div className="grid gap-1">
                  <h3 className="text-xl font-bold">Seamless Resume Automation</h3>
                  <p className="text-muted-foreground">
                    Effortlessly create customized resumes for each job application, saving you time and maximizing your chances of success.
                  </p>
                </div>
              </li>
              <li>
                <div className="grid gap-1">
                  <h3 className="text-xl font-bold">AI-Powered Precision</h3>
                  <p className="text-muted-foreground">
                    Utilize our advanced AI technology to craft resumes that captivate employers and highlight your unique strengths.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}


function TestimonialSection() {
  return (
    <section id="about" className="w-full py-12 md:py-24 lg:py-32 border-t">
      <div className="container grid items-center justify-center gap-4 px-4 text-center md:px-6 lg:gap-10 m-auto">
        <div className="space-y-3">
          <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
            Join Thousands of Successful Job Seekers
          </h2>
          <p className="mx-auto max-w-[700px] text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
            Our users have landed their dream jobs using AutoResume. See what
            they have to say about our platform.
          </p>
        </div>
        <div className="divide-y rounded-lg border">
          <div className="grid w-full grid-cols-3 items-stretch justify-center divide-x md:grid-cols-3">
            <div className="mx-auto flex w-full items-center justify-center">
              <img
                src="/image1.jpg"
                width="280"
                height="150"
                alt="Logo"
                className="overflow-hidden rounded-lg object-contain object-center m-1"
              />
            </div>
            <div className="mx-auto flex w-full items-center justify-center">
              <img
                src="/image2.jpg"
                width="280"
                height="150"
                alt="Logo"
                className="overflow-hidden rounded-lg object-contain object-center m-1"
              />
            </div>
            <div className="mx-auto flex w-full items-center justify-center">
              <img
                src="/image3.jpg"
                width="280"
                height="150"
                alt="Logo"
                className="overflow-hidden rounded-lg object-contain object-center m-1"
              />
            </div>
          </div>
          <div className="grid w-full grid-cols-3 items-stretch justify-center divide-x md:grid-cols-3">
            <div className="mx-auto flex w-full items-center justify-center">
              <img
                src="/image4.jpg"
                width="280"
                height="150"
                alt="Logo"
                className="overflow-hidden rounded-lg object-contain object-center m-1"
              />
            </div>
            <div className="mx-auto flex w-full items-center justify-center">
              <img
                src="/image5.jpg"
                width="280"
                height="150"
                alt="Logo"
                className="overflow-hidden rounded-lg object-contain object-center m-1"
              />
            </div>
            <div className="mx-auto flex w-full items-center justify-center">
              <img
                src="/image6.jpg"
                width="280"
                height="150"
                alt="Logo"
                className="overflow-hidden rounded-lg object-contain object-center m-1"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2 min-[400px]:flex-row justify-center">
          <a
            href="https://chromewebstore.google.com/detail/autoresume/hmkameegcicjjiahjccjabfmlkggfocf"
            className="inline-flex h-10 items-center justify-center rounded-md bg-primary px-8 text-sm font-medium text-primary-foreground shadow transition-colors hover:bg-primary/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
            prefetch={false}
          >
            Try for Free
          </a>
          <a
            href="#"
            className="inline-flex h-10 items-center justify-center rounded-md border border-input bg-background px-8 text-sm font-medium shadow-sm transition-colors hover:bg-accent hover:text-accent-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
            prefetch={false}
          >
            Learn More
          </a>
        </div>
      </div>
    </section>
  );
}

function VideoSection() {
  return (
    <section id="about" className="w-full py-12 md:py-24 lg:py-32 border-t">
      <div className="container grid items-center justify-center gap-4 px-4 text-center md:px-6 lg:gap-10 m-auto">
        <div className="space-y-3">
          <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
            Watch How It Crafts the Perfect Resume
          </h2>
          <p className="mx-auto max-w-[700px] text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
            Effortless Resume Creation with Cutting-Edge Technology
          </p>
        </div>
        <div className="rounded-xl overflow-hidden shadow-lg">
          {/* <video class=" h-56" controls>
            <source src="https://www.loom.com/share/c82501c7a6404d028e4ce9a0e640e5ea?sid=bbc1875c-c0f4-48b6-b0ee-1c6da3b5d744" />
            Your browser does not support the video tag.
          </video> */}
          <div
            style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}
          >
            <iframe
              src="https://www.loom.com/embed/c82501c7a6404d028e4ce9a0e640e5ea?sid=bbd85734-e37b-42bb-8c2a-817b0d2ff4b5"
              frameBorder="0"
              title="video"
              webkitAllowFullScreen
              mozAllowFullScreen
              allowFullScreen
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
}

function Pricing() {
  return (
    <section id="pricing" className="relative overflow-hidden py-10">
      <div className="relative mx-auto max-w-7xl px-4 ">
        <div className="mx-auto mb-24 max-w-2xl text-center lg:max-w-5xl">
          <h1 className="mb-8 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl lg:text-7xl">
            Our Plans
          </h1>
          {/* <p className="text-lg text-gray-500">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam, blanditiis.
            </p> */}
        </div>
        <div className="mx-auto max-w-5xl">
          <div className="flex flex-wrap items-center">
            <div className="w-full lg:-mr-2 lg:w-1/3 ">
              <div className="mx-auto max-w-sm rounded-md border border-gray-200 bg-white pb-20 pl-5 pr-8 pt-6 lg:pb-8 flex justify-center items-center flex-col">
                <span className="mb-2  text-sm font-semibold text-gray-400">
                  STARTER
                </span>
                <span className="flex items-end">
                  <span className="text-4xl font-extrabold leading-none">
                    $5
                  </span>
                  <span className="text-sm font-semibold">/month</span>
                </span>
                <div className="mt-7 border-t border-gray-100 pt-5">
                  <ul className="mb-10 text-center">
                    <li className="mb-6 flex items-center justify-center">
                      <span className="text-sm text-gray-900">Basic Resume Templates</span>
                    </li>
                    <li className="mb-6 flex items-center justify-center">
                      <span className="text-sm text-gray-900">Download in PDF Format</span>
                    </li>
                    <li className="mb-6 flex items-center justify-center">
                      <span className="text-sm text-gray-900">Email Support</span>
                    </li>
                    <li className="mb-6 flex items-center justify-center">
                      <span className="text-sm text-gray-900">Access to Community Forum</span>
                    </li>
                  </ul>
                  <button
                    type="button"
                    className="rounded-md border border-black px-3 py-2 text-sm font-semibold text-black shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                    onClick={() =>
                      (window.location.href =
                        "https://chromewebstore.google.com/detail/autoresume/hmkameegcicjjiahjccjabfmlkggfocf")
                    }
                  >
                    GET EXTENSION NOW
                  </button>
                </div>
              </div>
            </div>
            <div className="-mt-4 w-full lg:-mt-0 lg:w-1/3">
              <div className="pt-22 relative mx-auto max-w-sm rounded-lg bg-black px-10 pb-16 flex justify-center items-center flex-col">
                <div className="absolute left-1/2 top-0 inline-flex -translate-x-1/2 -translate-y-1/2 transform items-center justify-center rounded-full bg-white p-2">
                  <div className="flex-shrink-0 rounded-full bg-black px-5 py-4 text-sm font-semibold uppercase text-white">
                    Most Popular
                  </div>
                </div>
                <span className="mb-2 block pt-10 text-sm font-semibold text-white">
                  PROFESSIONAL
                </span>
                <span className="flex items-end text-white">
                  <span className="text-4xl font-extrabold leading-none">
                    $8.50
                  </span>
                  <span className="text-sm font-semibold">/month</span>
                </span>
                <div className="mt-7 border-t border-[hsl(346.8_77.2%_49.8%)]">
                  <ul className="my-5 text-center">
                    <li className="mb-6 flex items-center justify-center">
                      <span className="ml-2 text-sm text-white">All Starter Features</span>
                    </li>
                    <li className="mb-6 flex items-center justify-center">
                      <span className="ml-2 text-sm text-white">Advanced Resume Templates</span>
                    </li>
                    <li className="mb-6 flex items-center justify-center">
                      <span className="ml-2 text-sm text-white">Download in DOCX and PDF</span>
                    </li>
                    <li className="mb-6 flex items-center justify-center">
                      <span className="ml-2 text-sm text-white">Priority Email Support</span>
                    </li>
                    <li className="mb-6 flex items-center justify-center">
                      <span className="ml-2 text-sm text-white">Access to Premium Forum</span>
                    </li>
                  </ul>
                  <button
                    type="button"
                    className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                    onClick={() =>
                      (window.location.href =
                        "https://chromewebstore.google.com/detail/autoresume/hmkameegcicjjiahjccjabfmlkggfocf")
                    }
                  >
                    GET EXTENSION NOW
                  </button>
                </div>
              </div>
            </div>
            <div className="-mt-4 w-full lg:-ml-2 lg:-mt-0 lg:w-1/3">
              <div className="rounded-b-5xl lg:rounded-r-5xl mx-auto max-w-sm border border-gray-200 bg-white pb-8 pl-8 pr-5 pt-12 lg:rounded-b-none lg:pt-6 flex justify-center items-center flex-col">
                <span className="mb-2 block text-sm font-semibold text-gray-400">
                  ENTERPRISE
                </span>
                <span className="flex items-end">
                  <span className="text-4xl font-extrabold leading-none">
                    $10
                  </span>
                  <span className="text-sm font-semibold">/month</span>
                </span>
                <div className="mt-7 border-t border-gray-100 pt-5">
                  <ul className="mb-10 text-center">
                    <li className="mb-6 flex items-center justify-center">
                      <span className="ml-2 text-sm text-gray-900">All Professional Features</span>
                    </li>
                    <li className="mb-6 flex items-center justify-center">
                      <span className="ml-2 text-sm text-gray-900">Custom Resume Templates</span>
                    </li>
                    <li className="mb-6 flex items-center justify-center">
                      <span className="ml-2 text-sm text-gray-900">Download in Multiple Formats</span>
                    </li>
                    <li className="mb-6 flex items-center justify-center">
                      <span className="ml-2 text-sm text-gray-900">Dedicated Account Manager</span>
                    </li>
                    <li className="mb-6 flex items-center justify-center">
                      <span className="ml-2 text-sm text-gray-900">24/7 Phone Support</span>
                    </li>
                  </ul>
                  <button
                    type="button"
                    className="rounded-md border border-black px-3 py-2 text-sm font-semibold text-black shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                    onClick={() =>
                      (window.location.href =
                        "https://chromewebstore.google.com/detail/autoresume/hmkameegcicjjiahjccjabfmlkggfocf")
                    }
                  >
                    GET EXTENSION NOW
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}


function Footer() {
  return (
    <div>
      <div className="flex items-center justify-between border-t px-4 md:px-6 py-4">
        <span href="#" className="flex items-center" prefetch={false}>
          <img
            src="/1024-logo.png"
            alt="logo"
            className=" w-12 aspect-square"
          />
          <span className="sr-only">AutoResume</span>
        </span>
        <a
          href="https://www.linkedin.com/in/devsm/"
          className="text-muted-foreground hover:text-foreground"
          prefetch={false}
        >
          <LinkedinIcon className="h-6 w-6" />
          <span className="sr-only">LinkedIn</span>
        </a>
      </div>

      <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6">
        <p className="text-xs text-muted-foreground">
          &copy; 2024 AutoResume. All rights reserved.
        </p>
        <nav className="sm:ml-auto flex gap-4 sm:gap-6">
          <a
            href="/tos.html"
            className="text-xs hover:underline underline-offset-4"
            prefetch={false}
          >
            TOS
          </a>
          <a
            href="/policy.html"
            className="text-xs hover:underline underline-offset-4"
            prefetch={false}
          >
            Privacy
          </a>
          <a
            href="/cancellation-policy.html"
            className="text-xs hover:underline underline-offset-4"
            prefetch={false}
          >
            Cancellation Policy
          </a>
          <a
            href="/customer-support.html"
            className="text-xs hover:underline underline-offset-4"
            prefetch={false}
          >
            Support
          </a>
        </nav>
      </footer>
    </div>
  );
}

export default function LandingPage() {
  useEffect(()=>{
    ReactGA.send({ hitType: "LandingPage", page: window?.location?.pathname });

  },[])
  return (
    <div className="flex flex-col min-h-[100dvh] ">
      <Header />
      <main className="flex-1">
        <HeroSection />
        <VideoSection />
        <FeatureSection />
        <TestimonialSection />
        <Pricing />
      </main>
      <Footer />
    </div>
  );
}
