import logo from './logo.svg';
import './App.css';
import LandingPage from './LandingPage/components/LandingPage';

function App() {
  return (
    <LandingPage />
  )
}

export default App;
